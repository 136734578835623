import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/privateRoute";
import { GetCurrentUser } from "./actions/auth";
import CoreLayout from "./layouts/coreLayout";
import { Loading } from "@nextui-org/react";
import LoginPage from "./pages/auth/loginPage";
import styled from "styled-components";

function App({ authDispatch, history }) {
  const [hasLoaded, updateHasLoaded] = useState(false);
  const [shouldShowNotif, updateShouldShowNotif] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("auth-token");
    if (!localStorage.getItem("auth-token")) return updateHasLoaded(true);
    if (token) {
      GetCurrentUser()
        .then((response) => {
          if (response.data.message === "Invalid Token") {
            authDispatch({ type: "logged-out" });
            localStorage.removeItem("auth-token");
            updateHasLoaded(true);
          }
          // setting that we should watch for errors again
          updateShouldShowNotif(true);
          // This is just for checking a saved token in local is valid
          authDispatch({
            type: "valid-login",
            payload: {
              token: localStorage.getItem("auth-token"),
              role: response.data.role,
              user: response.data,
            },
          });
          updateHasLoaded(true);
        })
        .catch((err) => {
          console.log(err);
          authDispatch({ type: "logged-out" });
          localStorage.removeItem("auth-token");
          updateHasLoaded(true);
        });
    }
    // eslint-disable-next-line
  }, []);

  return hasLoaded ? (
    <BrowserRouter>
      <Switch>
        <Route
          path="/login"
          render={() => (
            <LoginPage history={history} authDispatch={authDispatch} />
          )}
        />
        <PrivateRoute
          path="/"
          history={history}
          authDispatch={authDispatch}
          Component={CoreLayout}
        />
      </Switch>
    </BrowserRouter>
  ) : (
    <FullPage>
      <Loading color="success" />
    </FullPage>
  );
}

const FullPage = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default App;
