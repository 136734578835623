import React, { useEffect, useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import styled from "styled-components";
import { Tooltip, Button } from "@nextui-org/react";
import { Helmet } from "react-helmet";

const NavBar = ({ authState, history, authDispatch }) => {
  const [currLoc, setCurrLoc] = useState(1);
  let loc = useLocation().pathname;

  useEffect(() => {
    let isInView = true;
    if (isInView) {
      if (loc === "/") {
        setCurrLoc(1);
      } else if (loc.includes("accounts")) {
        setCurrLoc(2);
      } else if (loc.includes("settings")) {
        setCurrLoc(3);
      } else if (loc.includes("profile")) {
        setCurrLoc(4);
      } else if (loc.includes("logout")) {
        setCurrLoc(5);
      }
    }
    return () => {
      isInView = false;
    };
  }, [loc]);

  const logOut = () => {
    authDispatch({ type: "logged-out" });
    localStorage.removeItem("auth-token");
    history.push("/login");
  };

  return (
    <AllOver>
      <NavBlock>
        <a href="/">
          <h4>TaxHype</h4>
        </a>
        <EndNav>
          <a href="/">
            <h4 className={currLoc === 1 ? "active" : ""}>Home</h4>
          </a>
          <a href="/accounts">
            <h4 className={currLoc === 2 ? "active" : ""}>Accounts</h4>
          </a>
          <a href="/settings">
            <h4 className={currLoc === 3 ? "active" : ""}>Settings</h4>
          </a>
          <a href="/profile">
            <h4 className={currLoc === 4 ? "active" : ""}>Profile</h4>
          </a>
          <a onClick={() => logOut()}>
            <h4 className={currLoc === 5 ? "active" : ""}>Sign Out</h4>
          </a>
        </EndNav>
      </NavBlock>
    </AllOver>
  );
};

const EndNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  h4 {
    font-weight: 400;
  }
  a {
    text-decoration: none;
    cursor: pointer;
  }
  .active {
    font-weight: 600;
    color: #0ca940;
  }
`;

const AllOver = styled.div`
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
`;

const NavBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  height: 60px;
  transition: 0.3s;
  a {
    text-decoration: none;
    color: #000;
  }
  a:hover {
    color: #0ca940;
    transition: 0.3s;
  }
`;

export default withRouter(NavBar);
