import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Loading, Button } from "@nextui-org/react";
import { Helmet } from "react-helmet";
import { GetAccounts } from "../../actions/auth";

const DashboardPage = ({ authState, history, authDispatch }) => {
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);

  let user = authState.user;

  useEffect(() => {
    let isInView = true;
    if (isInView) {
      GetAccounts().then((res) => {
        setAccounts(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 700);
      });
    }
    return () => {
      isInView = false;
    };
  }, []);

  useEffect(() => {
    if (authState.isAuth === false) {
      logUserOut();
    } else {
      authChecker();
    }
  }, [authState]);

  const authChecker = () => {
    if (authState.isAuth === false) {
      logUserOut();
    } else {
      setTimeout(() => {
        authChecker();
      }, 2000);
    }
  };

  const logUserOut = () => {
    authDispatch({ type: "logged-out" });
    localStorage.removeItem("auth-token");
  };

  const openAccount = (acc) => {
    history.push("/accounts/" + acc);
  };

  const renderAccs = () => {
    return accounts.map((acc) => {
      return (
        <div className="account" onClick={() => openAccount(acc.accountId)}>
          <p>Account Name:</p>
          <h4>{acc.accountName}</h4>
        </div>
      );
    });
  };

  const renderAddNew = () => {
    return (
      <div className="account">
        <h4 style={{ margin: 0 }}>+ Add New Account</h4>
      </div>
    );
  };

  return (
    <Content>
      <Helmet>
        <title>TaxHype | Dashboard</title>
      </Helmet>
      <LineBlock>
        <h2>
          <span style={{ fontWeight: 400 }}>Welcome back,</span>
          <br />
          {user.firstName} {user.lastName}
        </h2>
      </LineBlock>
      <LineBlock>
        <h3>Accounts</h3>
        <InlineAccounts>
          {loading ? (
            <Loading color="success" />
          ) : accounts.length > 0 ? (
            <>
              {renderAddNew()}
              {renderAccs()}
            </>
          ) : (
            <>
              {renderAddNew()}
              <h4>No Accounts Found</h4>
            </>
          )}
        </InlineAccounts>
      </LineBlock>
    </Content>
  );
};

const InlineAccounts = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin: 20px 0;
  .account {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 90px;
    background: #dfe6e9;
    padding: 10px;
    border-radius: 10px;
    h4 {
      margin-top: 0;
      font-size: 16px;
      font-weight: 700;
    }
    p {
      margin-bottom: 0;
    }
    cursor: pointer;
  }
  .account:hover {
    background: #78e08f;
    transition: all 0.3s ease-in-out;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  margin-top: 20px;
`;
const LineBlock = styled.div`
  width: 75%;
  margin: 0 auto;
  border-bottom: 1px solid #e6e6e6;
`;

export default withRouter(DashboardPage);
