import axios from "axios";

// let api_url = "http://localhost:3001";
let api_url = "https://api.taxhype.com/";

const axiosInstance = axios.create({
  baseURL: api_url,
  headers: {
    token: localStorage.getItem("auth-token"),
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("auth-token");
    if (token) {
      config.headers["token"] = `${token}`;
    }
    return config;
  },
  (error) => {
    console.log("ERROR ON CONFIG");
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger

    // eslint-disable-next-line
    if (error.response.data.message == "ACCESS FORBIDDEN! User unauthorized.") {
      localStorage.removeItem("auth-token");
      // window.location.reload();
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
