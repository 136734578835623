import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { AuthProvider, AuthConsumer } from "./context/context";

ReactDOM.render(
  <AuthProvider>
    <AuthConsumer>
      {(authState) => (
        <App authState={authState} authDispatch={authState.authDispatch} />
      )}
    </AuthConsumer>
  </AuthProvider>,
  document.getElementById("root")
);
