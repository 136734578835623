import axiosInstance from "./axios";

export const GetCurrentUser = () => {
  return axiosInstance.get("/auth/user");
};

export const Register = (data) => {
  return axiosInstance.post("/auth/register", data);
};

export const Login = (data) => {
  return axiosInstance.post("/auth/login", data);
};

export const GetAccounts = () => {
  return axiosInstance.get("/auth/getAccounts");
};
export const GetAccount = (data) => {
  return axiosInstance.post("/auth/getAccount", data);
};
