import React from "react";
import { withRouter, Route, Switch, Redirect } from "react-router-dom";
import styled from "styled-components";
import DashboardPage from "../pages/dashboard/dashboard";
import NavBar from "../components/navBar";
import OpenAccount from "../pages/dashboard/openAccount";
import Accounts from "../pages/dashboard/accounts";
import ShopifyConnect from "../pages/shopifyConnect";
import PreviewData from "../pages/dashboard/previewData";

const CoreLayout = ({ match, authState, history, authDispatch }) => {
  // The user must meet this requirement of completed level to use the app

  return (
    <Content>
      <NavBar
        authState={authState}
        history={history}
        authDispatch={authDispatch}
      />
      <Switch>
        <Route
          exact
          path="/linkShopify"
          render={(routeProps) =>
            authState.isAuth ? (
              <ShopifyConnect authState={authState} history={history} />
            ) : (
              <Redirect to={{ pathname: "/login" }} />
            )
          }
        />
        <Route
          exact
          path="/accounts"
          render={(routeProps) =>
            authState.isAuth ? (
              <Accounts authState={authState} history={history} />
            ) : (
              <Redirect to={{ pathname: "/login" }} />
            )
          }
        />
        <Route
          exact
          path="/accounts/:accountId/previewData"
          render={(routeProps) =>
            authState.isAuth ? (
              <PreviewData
                authState={authState}
                history={history}
                accountId={routeProps.match.params.accountId}
              />
            ) : (
              <Redirect to={{ pathname: "/accounts" }} />
            )
          }
        />
        <Route
          exact
          path="/accounts/:accountId"
          render={(routeProps) =>
            authState.isAuth ? (
              <OpenAccount
                authState={authState}
                history={history}
                accountId={routeProps.match.params.accountId}
              />
            ) : (
              <Redirect to={{ pathname: "/login" }} />
            )
          }
        />
        <Route
          exact
          path="/"
          render={() =>
            authState.isAuth ? (
              <DashboardPage
                authState={authState}
                authDispatch={authDispatch}
                history={history}
              />
            ) : (
              <Redirect to={{ pathname: "/login" }} />
            )
          }
        />
      </Switch>
    </Content>
  );
};
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  background: #f6f9fb;
`;

export default withRouter(CoreLayout);
