import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthConsumer } from "../context/context";

const PrivateRoute = ({ Component, authDispatch, ...props }) => (
  <AuthConsumer>
    {(authState) => (
      <Route
        {...props}
        render={(props) =>
          authState.isAuth ? (
            <Component
              authDispatch={authDispatch}
              authState={authState}
              {...props}
            />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )
        }
      />
    )}
  </AuthConsumer>
);

export default PrivateRoute;
