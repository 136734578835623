import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { TextInput, Button, CornerDialog } from "evergreen-ui";
import { Input } from "antd";
// import { Spin } from "antd";
import { Login } from "../../actions/auth";

const LoginPage = ({ history, authDispatch }) => {
  const [errorDiag, setErrorDiag] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  let submitLogin = () => {
    let temp = loginData;
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(temp.email)) {
      setLoading(true);
      Login(temp)
        .then((res) => {
          authDispatch({
            type: "valid-login",
            payload: {
              token: res.data.token,
              email: res.data.user.email,
              user: res.data.user,
            },
          });
          history.push("/");
        })
        .catch((err) => {
          setErrorMessage(err.response?.data?.message);
          setErrorDiag(true);
          setLoading(false);
          setTimeout(() => {
            setErrorDiag(false);
            setErrorMessage("");
          }, 3000);
        });
    } else {
      if (!errorDiag) {
        setErrorMessage("Please enter a valid email address");
        setErrorDiag(true);
        setTimeout(() => {
          setErrorDiag(false);
          setErrorMessage("");
        }, 3000);
      } else {
        setErrorMessage("Please enter a valid email address");
        setErrorDiag(true);
        setTimeout(() => {
          setErrorDiag(false);
          setErrorMessage("");
        }, 3000);
      }
    }
  };

  const changeVal = (e) => {
    let temp = loginData;
    temp[e.target.id] = e.target.value;
    setLoginData(temp);
  };

  return (
    <>
      <Content>
        <Left>
          <h1>TaxHype</h1>
          <h3>This slogan needs some hype</h3>
        </Left>
        <Block>
          <div className="inner">
            <h2>Welcome Back ✌️</h2>
            <p>Jump right back into your account</p>
            <TextInput
              className="inputField"
              placeholder="Email Address"
              id="email"
              onChange={(e) => changeVal(e)}
            />
            <TextInput
              className="inputField"
              placeholder="Password"
              type="password"
              autoComplete="current-password"
              id="password"
              onChange={(e) => changeVal(e)}
            />
            <a href="/auth/passwordReset">Forgot your password?</a>
            <Button
              appearance="primary"
              height={36}
              className="signInButton"
              onClick={() => submitLogin()}
            >
              Login
            </Button>
            <Button appearance="minimal" height={36} className="signUpButton">
              Sign Up
            </Button>
          </div>
        </Block>
      </Content>
      <CornerDialog
        title="Error"
        isShown={errorDiag}
        onCloseComplete={() => setErrorDiag(false)}
        hasFooter={false}
        position="top-left"
      >
        {errorMessage}
      </CornerDialog>
    </>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f6f9fb;
`;

const Block = styled.div`
  width: 30%;
  height: 80%;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 15px 5px 60px rgba(0, 0, 0, 0.02);
  .inner {
    width: 80%;
    height: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 15px;
    align-items: center;
    h2 {
      width: 100%;
      text-align: right;
      margin: 0;
      margin-top: 40%;
    }
    p {
      width: 100%;
      text-align: right;
      margin: 0;
      color: #a5a5a5;
      margin-bottom: 15%;
    }
    .inputField {
      width: 100%;
      margin-top: 15px;
    }
    a {
      width: 100%;
      text-align: left;
      margin: 0;
      color: #a5a5a5;
      margin-bottom: 15px;
      margin-top: 5px;
      font-size: 12px;
    }
    a:hover {
      color: #0ca950;
    }
    .signInButton {
      width: 100%;
      // margin-top: 45px;
      margin-top: 35%;
      background: #0ca940;
      background-color: #0ca940;
      border-color: #0ca940;
    }
    .signInButton:hover {
      background: #087647;
      background-color: #087647;
      border-color: #087647;
    }
    .signUpButton {
      width: 100%;
      margin-top: 15px;
      border-color: #087647;
    }
    .signUpButton:hover {
      background: white;
      background-color: white;
      border-color: #0ca940;
    }
  }
}`;

const Left = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: -15%;
  margin-right: 10%;
  h1 {
    font-size: 72px;
    margin-bottom: 0;
    margin-top: 0;
  }
`;

export default withRouter(LoginPage);
