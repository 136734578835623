import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { GetAccount } from "../../actions/auth";
import { Spin, Divider } from "antd";
import moment from "moment";
import { Button } from "@nextui-org/react";

const PreviewData = ({ authState, history, accountId }) => {
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState({
    accountInformation: {
      address: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country: "",
      },
      billingAddress: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country: "",
      },
      federalTID: "",
    },
    plan: {
      paymentMethod: "",
    },
    accountId: "",
    accountName: "",
    users: [],
    nexusRegistrations: [],
    linkedAccounts: [],
    apiKeys: [],
  });

  useEffect(() => {
    let isInView = true;
    if (isInView) {
      setLoading(true);
      if (accountId) {
        GetAccount({ accountId: accountId })
          .then((res) => {
            setAccount(res.data);
            setTimeout(() => {
              setLoading(false);
            }, 700);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      } else {
        history.push("/accounts");
      }
    }
    return () => {
      isInView = false;
    };
  }, []);

  return (
    <>
      {loading ? (
        <Spin size="large" tip="Loading..." style={{ marginTop: "20%" }} />
      ) : (
        <>
          <h3>{account.accountName} | Nexus Data Preview</h3>
          <Divider />
        </>
      )}
    </>
  );
};

export default withRouter(PreviewData);
