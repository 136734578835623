import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { IntegrateShopify } from "../actions/account";

const ShopifyConnect = () => {
  let loc = useLocation();
  let params = new URLSearchParams(loc.search);
  const [credentials, setCredentials] = useState({
    accessToken: "",
    scope: "",
  });

  const doesMatch = () => {
    let shop = params.get("shop");
    let re = /^[a-zA-Z0-9][a-zA-Z0-9\-]*.myshopify.com/;
    if (re.test(shop)) {
      getAccessToken();
      return "Matching";
    } else {
      return "Invalid";
    }
  };
  useEffect(() => {
    let isInView = true;
    if (isInView) {
      let providedCreds = new URLSearchParams(loc.search);
      // let code = providedCreds.get("code");
      // let shop = providedCreds.get("shop");
      let shop = "taxhype.myshopify.com";
      let code = "44c4f5b4787389dabd54868fdabcf4f5";
      let apiKey = "a8eb6ee1ed05a6c9df64ba59baf7ac01";
      axios
        .post(`https://${shop}/admin/oauth/access_token`, {
          client_id: apiKey,
          client_secret: "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",
          code,
        })
        .then((res) => {
          console.log(res.data);
          let accessToken = res.data.access_token;
          let scope = res.data.scope;
          setCredentials({ accessToken, scope });
        })
        .catch((e) => {
          console.log("Auth Errored");
          console.error(e);
        });
    }
    return () => {
      isInView = false;
    };
  }, []);

  const getAccessToken = () => {
    console.log("Called");
    let code = params.get("code");
    let shop = params.get("shop").split(".myshopify.com")[0];
    if (shop.includes("/")) {
      shop = shop.split("/").pop();
    }
    IntegrateShopify({
      shop: shop,
      code: code,
      integrationId: params.get("state"),
    })
      .then((res) => {
        alert("Success!");
      })
      .catch((err) => {
        alert("Error");
      });
  };

  return (
    <>
      <h3>Shopify Auth</h3>
      <hr />
      <p style={{ textAlign: "center" }}>{JSON.stringify(loc.search)}</p>
      <br />
      <p style={{ textAlign: "center" }}>Shop: {params.get("shop")}</p>
      <br />
      <p style={{ textAlign: "center" }}>Shop Valid: {doesMatch()}</p>
      <br />
      <p style={{ textAlign: "center" }}>Nonce: {params.get("state")}</p>
      <br />
      <p style={{ textAlign: "center" }}>HMAC: {params.get("hmac")}</p>
      <br />
      <p style={{ textAlign: "center" }}>Code: {params.get("code")}</p>
      <br />
      <p style={{ textAlign: "center" }}>Host: {params.get("host")}</p>
      <br />
      <p style={{ textAlign: "center" }}>
        Creds: {credentials.accessToken} | {credentials.scope}
      </p>
      <br />
      <p style={{ textAlign: "center" }}>
        Timestamp: {params.get("timestamp")}
      </p>
      <br />
    </>
  );
};

export default ShopifyConnect;
