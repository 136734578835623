import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Loading, Button, Modal, Text } from "@nextui-org/react";
import { Helmet } from "react-helmet";
import { Divider, Spin } from "antd";
import { GetAccounts } from "../../actions/auth";
import moment from "moment";
import "./components/openAccount.css";
import { StartShopifyLink } from "../../actions/account";
import stripeLogo from "../../assets/stripe.png";

const OpenAccount = ({ authState, history, accountId }) => {
  const [loading, setLoading] = useState(false);
  const [addIntegration, setAddIntegration] = useState(false);
  const [integrationLoad, setIntegrationLoad] = useState(false);
  const [account, setAccount] = useState({
    accountInformation: {
      address: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country: "",
      },
      billingAddress: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country: "",
      },
      federalTID: "",
    },
    plan: {
      paymentMethod: "",
    },
    accountId: "",
    accountName: "",
    users: [],
    nexusRegistrations: [],
    linkedAccounts: [],
    apiKeys: [],
  });

  let user = authState.user;

  useEffect(() => {
    let isInView = true;
    if (isInView) {
      GetAccounts().then((res) => {
        let acc = res.data.find((acc) => acc.accountId === accountId);
        if (acc) {
          setAccount(acc);
          setTimeout(() => {
            setLoading(false);
          }, 700);
        } else {
          history.push("/accounts");
        }
      });
    }
    return () => {
      isInView = false;
    };
  }, []);

  const startShopify = () => {
    setIntegrationLoad(true);
    StartShopifyLink({ accountId: accountId })
      .then((res) => {
        let link = `https://taxhype.myshopify.com/admin/oauth/authorize?client_id=a8eb6ee1ed05a6c9df64ba59baf7ac01&scope=read_orders&redirect_uri=https://dashboard.taxhype.com/linkShopify&state=${res.data.id}&grant_options[]=value&accountId=${accountId}`;
        window.location.href = link;
      })
      .catch((err) => {
        setIntegrationLoad(false);
        alert("Something went wrong.");
      });
  };

  const addIntegrationModal = () => {
    return (
      <Modal
        closeButton
        aria-labelledby="Add Integration"
        open={addIntegration}
        onClose={() => setAddIntegration(false)}
      >
        <Modal.Header>
          <Text id="add-integration" size={18}>
            Link a
            <Text b size={17}>
              {" "}
              new
            </Text>{" "}
            account
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Spin spinning={integrationLoad}>
            <div className="integrationType">
              <Text size={16}>Stripe</Text>
              <a
                href="https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_LA9WqgbEUu3CaQ5Bx7LHAEhMmqMHHcex&scope=read_only"
                class="stripe-connect slate"
              >
                <span>Connect with</span>
              </a>
            </div>
            <div className="integrationType">
              <Text size={16}>Shopify</Text>
              <Button auto flat color="primary" onClick={() => startShopify()}>
                Connect Shopify
              </Button>
            </div>
          </Spin>
        </Modal.Body>
        <Modal.Footer>
          <Button
            auto
            flat
            color="error"
            onClick={() => setAddIntegration(false)}
            disabled={integrationLoad}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const openAccount = (acc) => {
    history.push("/accounts/" + acc);
  };

  const renderAddNew = () => {
    return (
      <div className="account">
        <h4 style={{ margin: 0 }}>+ Add New Account</h4>
      </div>
    );
  };

  const renderIntegrationType = (type) => {
    if (type === "stripe") {
      return (
        <div className="integration">
          <img src={stripeLogo} alt="stripe" width={40} height="auto" />
          <h3>Stripe</h3>
          <div className="integrationActions">
            <Button bordered auto color="warning">
              Edit
            </Button>
            <Button bordered auto color="error">
              Remove
            </Button>
          </div>
        </div>
      );
    } else {
      return <h3>Unknown | {type}</h3>;
    }
  };

  return (
    <Content>
      <Helmet>
        <title>TaxHype | {account.accountName}</title>
      </Helmet>
      {loading ? (
        "Loading..."
      ) : (
        <LineBlock>
          <h3 style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 600 }}>{account.accountName}</span> |
            Account Preview
          </h3>
          <InlineAccounts>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div className="addressBlock">
                <h3>Office Address</h3>
                <Divider />
                <h4>
                  <span>Address: </span>
                  {account.accountInformation.address.address1}
                </h4>
                <h4>
                  <span>Unit / Suite: </span>
                  {account.accountInformation.address.address2}
                </h4>
                <h4>
                  <span>State: </span>
                  {account.accountInformation.address.state}
                </h4>
                <h4>
                  <span>ZIP Code: </span>
                  {account.accountInformation.address.zip}
                </h4>
              </div>
              <div className="addressBlock">
                <h3>Billing Address</h3>
                <Divider />
                <h4>
                  <span>Address: </span>
                  {account.accountInformation.billingAddress.address1}
                </h4>
                <h4>
                  <span>Unit / Suite: </span>
                  {account.accountInformation.billingAddress.address2}
                </h4>
                <h4>
                  <span>State: </span>
                  {account.accountInformation.billingAddress.state}
                </h4>
                <h4>
                  <span>ZIP Code: </span>
                  {account.accountInformation.billingAddress.zip}
                </h4>
              </div>
            </div>
          </InlineAccounts>
          <InlineAccounts style={{ marginTop: "30px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div className="addressBlock">
                <h3
                  style={{
                    textAlign: "left",
                    marginRight: "20px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  Linked Accounts{" "}
                  <span style={{ float: "right" }}>
                    <Button
                      color="success"
                      ghost
                      auto
                      onClick={() => setAddIntegration(true)}
                    >
                      Add New
                    </Button>
                  </span>
                </h3>
                <Divider />
                <VerticalList>
                  {account.linkedAccounts.map((key) => {
                    return renderIntegrationType(key.integration);
                  })}
                </VerticalList>
              </div>
              <div className="addressBlock">
                <h3>API Keys</h3>
                <Divider />
                <VerticalList>
                  {account.apiKeys.map((key) => {
                    return (
                      <div className="VListItem">
                        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
                          Issued:{" "}
                          <span style={{ float: "right", fontWeight: 400 }}>
                            {moment(key.issued).format("lll")}
                          </span>
                        </h4>
                        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
                          Production Key:{" "}
                          <span style={{ float: "right", fontWeight: 400 }}>
                            {key.live ? "Yes" : "No"}
                          </span>
                        </h4>
                        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
                          Valid:{" "}
                          <span style={{ float: "right", fontWeight: 400 }}>
                            {key.valid ? "Yes" : "No"}
                          </span>
                        </h4>
                      </div>
                    );
                  })}
                </VerticalList>
              </div>
            </div>
          </InlineAccounts>
          <InlineAccounts style={{ marginTop: "30px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div className="addressBlock">
                <h3>Users</h3>
                <Divider />
              </div>
              <div className="addressBlock">
                <h3>Nexus Registartions</h3>
                <Divider />
              </div>
            </div>
          </InlineAccounts>
        </LineBlock>
      )}
      <div
        style={{
          margin: "0 auto",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "80%",
          marginTop: "20px",
        }}
      >
        <Button flat auto>
          Preview Data
        </Button>
      </div>
      {addIntegrationModal()}
    </Content>
  );
};
const VerticalList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
  height: 60%;
  overflow-y: auto;
  gap: 10px;
  .VListItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: rgba(255, 255, 255, 0.4);
    width: 100%;
    height: 100px;
    border-radius: 10px;
  }
  .integration {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    width: 95%;
    margin: 0 auto;
    padding: 5px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    img {
      height: 40px;
      width: 50px;
      border-radius: 20%;
      object-fit: scale-down;
      margin-left: 10px;
      margin-right: -10px;
    }
  }
  .integrationActions {
    float: right;
    margin-right: 10px;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
  }
`;

const InlineAccounts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
  .addressBlock {
    height: 250px;
    background: #dfe6e9;
    padding: 10px;
    border-radius: 10px;
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    h3 {
      font-weight: 400;
      margin-left: 20px;
    }
    .ant-divider,
    .ant-divider-horizontal {
      width: 95% !important;
      margin: 0 auto !important;
      margin-top: 0px !important;
      margin-bottom: 5px !important;
      height: 1px !important;
      background: #a5a5a5 !important;
    }
    h4 {
      margin: 0;
      width: 90%;
      margin: 0 auto;
      font-weight: 400;
      text-align: right;
      span {
        float: left;
        font-weight: 600;
      }
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  margin-top: 20px;
`;
const LineBlock = styled.div`
  width: 75%;
  margin: 0 auto;
  border-bottom: 1px solid #e6e6e6;
`;

export default withRouter(OpenAccount);
